import { ResourcesConfig } from 'aws-amplify';

export const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      identityPoolId: String(
        process.env.REACT_APP_ADMIN_COGNITO_IDENTITY_POOL_ID,
      ),
      userPoolId: String(process.env.REACT_APP_ADMIN_COGNITO_USER_POOL_ID),
      userPoolClientId: String(
        process.env.REACT_APP_ADMIN_COGNITO_APP_CLIENT_ID,
      ),
      allowGuestAccess: true,
      loginWith: {
        oauth: {
          domain: String(process.env.REACT_APP_ADMIN_COGNITO_DOMAIN),
          scopes: ['email', 'openid', 'phone', 'profile'],
          redirectSignIn: [
            String(process.env.REACT_APP_ADMIN_COGNITO_REDIRECT_SIGN_IN),
          ],
          redirectSignOut: [
            String(process.env.REACT_APP_ADMIN_COGNITO_REDIRECT_SIGN_OUT),
          ],
          responseType: 'code',
          providers: [],
        },
        email: true,
        phone: true,
        username: true,
      },
    },
  },
  API: {
    GraphQL: {
      endpoint: String(process.env.REACT_APP_ADMIN_APPSYNC_API_ENDPOINT),
      region: 'eu-west-2',
      defaultAuthMode: 'lambda',
    },
  },
};
