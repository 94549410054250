import {
  FormLabel,
  Grid,
  Slider as MuiSlider,
  SliderProps as MuiSliderProps,
  Typography,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const WhiteSlider = styled(MuiSlider)({
  marginTop: 0,
  padding: 0,
  color: '#fff',
  '& .MuiSlider-thumb': {
    backgroundColor: '#fff',
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#fff',
  },
  '& .MuiSlider-track': {
    backgroundColor: '#fff',
  },
  '& .MuiSlider-valueLabel': {
    color: '#fff',
  },
});

interface ValueLabelComponentProps {
  children: React.ReactElement;
  value: number;
}

function ValueLabelComponent({ children, value }: ValueLabelComponentProps) {
  return (
    <Tooltip
      title={value}
      placement="top"
      arrow
    >
      {children}
    </Tooltip>
  );
}

type SliderProps = MuiSliderProps & {
  max: number;
  label?: string;
  step?: number;
  value?: number;
  defaultValue?: number;
  columns?: 1 | 2 | 3;
  setValue: (value: number) => void;
};

export function Slider({
  label,
  max,
  step,
  value,
  defaultValue,
  columns = 2,
  setValue,
}: SliderProps) {
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  return (
    <Grid container>
      {columns > 1 && (
        <Grid
          item
          sm={columns === 2 ? 4 : 3}
          xs={columns === 3 ? 11 : 12}
        >
          {label && <FormLabel>{label}</FormLabel>}
        </Grid>
      )}
      {columns === 3 && (
        <Grid
          item
          xs={1}
        >
          <Typography>{value}</Typography>
        </Grid>
      )}
      <Grid
        item
        sm={columns > 1 ? 8 : 12}
        xs={12}
      >
        <WhiteSlider
          onChange={handleChange}
          value={value}
          step={step || 1}
          marks
          defaultValue={defaultValue || 1}
          valueLabelDisplay="on"
          max={max || 10}
          slots={{
            valueLabel: ValueLabelComponent,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default Slider;
