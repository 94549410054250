export const adminInviteUsersMutation = `
  mutation AdminInviteUsers($input: AdminInviteUsersInput!) {
    adminInviteUsers(input: $input) {
      success
      message
    }
  }
`;

export const adminDeleteInviteMutation = `
  mutation AdminDeleteInvite($input: AdminDeleteInviteInput!) {
    adminDeleteInvite(input: $input) {
      success
      message
    }
  }
`;
