import { DateTime } from 'luxon';

const formatDateFilter = (fromDate?: string, toDate?: string) => {
  if (fromDate && toDate) {
    return `From ${DateTime.fromISO(fromDate).toFormat('MM/dd/yyyy')} to ${DateTime.fromISO(toDate).toFormat('MM/dd/yyyy')}`;
  }
  if (fromDate) {
    return `From ${DateTime.fromISO(fromDate).toFormat('MM/dd/yyyy')}`;
  }
  if (toDate) {
    return `Up until ${DateTime.fromISO(toDate).toFormat('MM/dd/yyyy')}`;
  }
  return '';
};

export default formatDateFilter;
