/* eslint-disable react/jsx-props-no-spreading */
import { useState, useCallback } from 'react';
import { useCSVReader } from 'react-papaparse';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { validateAndTransformPhoneNumber } from 'utils/validate-phonenumber';
import { Invitee } from '../invite-users';

type CSVRow = string[];

interface CSVReaderProps {
  onValidData: (data: Invitee[], hasErrors: boolean) => void;
}

interface CSVReaderResults {
  data: string[][];
}

interface GetRootProps {
  (): Record<string, unknown>;
}

interface CSVReaderProps {
  getRootProps?: GetRootProps;
  acceptedFile?: boolean;
}

export function UploadCSV({ onValidData }: CSVReaderProps) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [uploadedData, setUploadedData] = useState<Invitee[] | null>(null);

  const csvToJsonParse = useCallback(
    (csvData: CSVRow[]): [Invitee[], boolean] => {
      if (csvData.length < 2) {
        return [[], true];
      }

      const headers = csvData[0].map((header) => header.toLowerCase().trim());
      const dataRows = csvData.slice(1);
      let hasErrors = false;

      const firstNameIndex = headers.findIndex((h) => h.includes('first'));
      const lastNameIndex = headers.findIndex((h) => h.includes('last'));
      const phoneIndex = headers.findIndex((h) => h.includes('phone'));

      if (firstNameIndex === -1 || lastNameIndex === -1 || phoneIndex === -1) {
        return [[], true];
      }

      const transformedData = dataRows.map((row) => {
        const [isValid, formattedNumber] = validateAndTransformPhoneNumber(
          row[phoneIndex]?.trim() ?? '',
        );

        if (!isValid) {
          hasErrors = true;
        }

        const invitee: Invitee = {
          firstName: row[firstNameIndex]?.trim() ?? '',
          lastName: row[lastNameIndex]?.trim() ?? '',
          phone: formattedNumber,
        };

        return invitee;
      });

      return [transformedData, hasErrors];
    },
    [],
  );

  const handleUpload = (results: CSVReaderResults) => {
    const [transformedData, hasErrors] = csvToJsonParse(results.data);
    onValidData(transformedData, hasErrors);
    if (!hasErrors) {
      setUploadedData(transformedData);
    }
  };

  if (uploadedData) {
    return (
      <Box
        data-testid="uploaded-data-container"
        mx={2}
      >
        <Typography
          variant="h6"
          gutterBottom
          data-testid="uploaded-data-title"
        >
          Uploaded Data:
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Phone Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="uploaded-data-table">
            {uploadedData.map((row) => (
              <TableRow key={row.phone}>
                <TableCell data-testid="first-name-cell">
                  {row.firstName}
                </TableCell>
                <TableCell data-testid="last-name-cell">
                  {row.lastName}
                </TableCell>
                <TableCell data-testid="phone-cell">{row.phone}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  }

  return (
    <CSVReader
      onUploadAccepted={handleUpload}
      onDragOver={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(false);
      }}
    >
      {({ getRootProps, acceptedFile }: CSVReaderProps) => (
        <Box
          {...getRootProps?.()}
          data-testid="csv-upload-area"
          sx={{
            alignItems: 'center',
            border: `2px dashed #686868`,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            padding: 6,
            ...(zoneHover && {
              borderColor: '#686868',
            }),
          }}
        >
          {!acceptedFile ? (
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              py={6}
            >
              <Typography
                variant="body1"
                data-testid="upload-text"
              >
                Drop CSV file here or click to upload
              </Typography>
              <Typography
                variant="body2"
                mt={2}
                data-testid="csv-format-text"
              >
                CSV should include columns for first name, last name, and phone
                number
              </Typography>
            </Box>
          ) : null}
        </Box>
      )}
    </CSVReader>
  );
}
