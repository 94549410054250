import { createContext, useContext, useState, ReactNode, useMemo } from 'react';
import { Snackbar, Alert } from '@mui/material';

interface SnackbarContextState {
  showSnackbar: (
    message: string,
    severity?: 'success' | 'info' | 'warning' | 'error',
  ) => void;
}

const SnackbarContext = createContext<SnackbarContextState | undefined>(
  undefined,
);

export const useSnackbar = (): SnackbarContextState => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

interface SnackbarProviderProps {
  children: ReactNode;
}

export function SnackbarProvider({
  children,
}: SnackbarProviderProps): JSX.Element {
  const [snackbarState, setSnackbarState] = useState<{
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error' | undefined;
    open: boolean;
  }>({
    message: '',
    severity: undefined,
    open: false,
  });

  const showSnackbar = (
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error' = 'info',
  ) => {
    setSnackbarState({ message, severity, open: true });
  };

  const handleClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const contextValue = useMemo(() => ({ showSnackbar }), []);

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          severity={snackbarState.severity}
          sx={{ width: '100%' }}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}
