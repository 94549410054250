import React, {
  ReactNode,
  SyntheticEvent,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import Layout from 'layouts';
import { TabPanel } from 'components/atoms';
import { ChangePassword } from './components';

type TabLabel = 'Account' | 'Team' | 'Invite';

interface TabProps {
  component: ReactNode;
  label: TabLabel;
  disabled?: boolean;
  tooltipTitle?: string;
}

export function Settings() {
  const [value, setValue] = useState<number>(0);

  const handleChange = useCallback(
    (event: SyntheticEvent, newValue: number) => {
      setValue(newValue);
    },
    [],
  );

  const tabs: TabProps[] = useMemo(
    () => [
      {
        component: <ChangePassword />,
        label: 'Account',
      },
    ],
    [],
  );

  return (
    <Layout>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="settings tabs"
        >
          {tabs.map((tab, index) => {
            const tabElement = (
              <Tab
                key={tab.label}
                label={tab.label}
                disabled={tab.disabled}
                aria-label={`${tab.label} tab`}
                id={`settings-tab-${index}`}
                aria-controls={`settings-tabpanel-${index}`}
              />
            );
            return tabElement;
          })}
        </Tabs>
        {tabs.map((tab, index) => (
          <TabPanel
            key={tab.label}
            value={value}
            index={index}
          >
            {tab.component}
          </TabPanel>
        ))}
      </Box>
    </Layout>
  );
}

export default React.memo(Settings);
