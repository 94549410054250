import {
  AddReaction,
  DataObject,
  People,
  Person,
  QuestionAnswer,
} from '@mui/icons-material';
import { SideBarItemProps } from 'types/app';

export const UserSideBarItems: SideBarItemProps[] = [
  { title: 'User', icon: <Person />, path: (id) => `/user/${id}` },
  { title: 'Data', icon: <DataObject />, path: (id) => `/user/${id}/data` },
];

export const MainSideBarItems: SideBarItemProps[] = [
  { title: 'Users', icon: <People />, path: () => '/' },
  { title: 'Invite', icon: <AddReaction />, path: () => '/invite' },
  { title: 'Prompt', icon: <QuestionAnswer />, path: () => '/prompt' },
];
