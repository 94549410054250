import { DateTime } from 'luxon';
import { SuperfeelUserResult } from 'types/api';

export interface UserField {
  label: string;
  key: keyof SuperfeelUserResult;
  format?: (value: unknown) => string;
}

const formatDate = (date: unknown): string => {
  if (!date) return '';
  return DateTime.fromISO(date as string).toFormat('dd-LLL-yy HH:mm');
};

export const userFields: UserField[] = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Username', key: 'username' },
  { label: 'Email', key: 'email' },
  { label: 'Date Created', key: 'dateCreated', format: formatDate },
  { label: 'Deleted At', key: 'deletedAt', format: formatDate },
];
