import React, { useEffect, useState } from 'react';
import {
  TextField,
  Typography,
  Box,
  FormControlLabel,
  Switch,
  Button,
  styled,
} from '@mui/material';
import Papa from 'papaparse';
import CloudUpload from '@mui/icons-material/CloudUpload';
import { Question, QuestionStatusTypes } from 'types/app';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: 1,
});

interface CSVUploadAndPasteProps {
  onComplete: (questions: Question[]) => void;
  data: Question[];
  headers: boolean;
  setHeaders: (headers: boolean) => void;
}

function Upload({
  onComplete,
  data,
  headers,
  setHeaders,
}: CSVUploadAndPasteProps) {
  const [textAreaValue, setTextAreaValue] = useState('');

  useEffect(() => {
    if (data.length > 0) {
      const csvData = Papa.unparse(data, { header: headers });
      setTextAreaValue(csvData);
    }
  }, [data, headers]);

  const processResults = (results: Papa.ParseResult<Question>) => {
    const dataWithIds = results.data.map((item, index) => ({
      ...item,
      id: index,
      status: item.status || QuestionStatusTypes.waiting,
    }));
    onComplete(dataWithIds);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      Papa.parse<Question>(file, {
        header: headers,
        dynamicTyping: true,
        complete: (results) => {
          processResults(results);
        },
        skipEmptyLines: true,
      });
    }
  };

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const newTextValue = event.target.value;
    setTextAreaValue(newTextValue);

    Papa.parse<Question>(newTextValue, {
      header: headers,
      dynamicTyping: true,
      complete: (results) => {
        processResults(results);
      },
      skipEmptyLines: true,
    });
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeaders(event.target.checked);
  };

  return (
    <Box sx={{ margin: 2 }}>
      <Typography variant="h6">Upload CSV or Paste Content</Typography>
      <Box mt={4}>
        <Button
          component="label"
          variant="contained"
          color="primary"
          startIcon={<CloudUpload />}
        >
          Upload CSV
          <VisuallyHiddenInput
            accept=".csv"
            type="file"
            onChange={handleFileChange}
          />
        </Button>
      </Box>
      <Box mt={4}>
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={headers}
              onChange={handleSwitchChange}
            />
          }
          label="Data has Header"
        />
        <TextField
          sx={{ mt: 2 }}
          label="Paste CSV Content Here"
          multiline
          fullWidth
          rows={4}
          variant="outlined"
          value={textAreaValue}
          onChange={handleTextAreaChange}
          placeholder="Enter CSV data here..."
        />
      </Box>
    </Box>
  );
}

export default Upload;
