import { DateTime } from 'luxon';

export const formatDate = (dateString?: string, format?: string) => {
  return DateTime.fromISO(dateString!).toFormat(
    format || 'dd-MMM-yy - HH:mm:ss',
  );
};

export function truncateText(text: string, maxWords: number): string {
  const words = text?.split(' ');
  if (words?.length > maxWords) {
    return `${words?.slice(0, maxWords).join(' ')} ...`;
  }
  return text;
}

export const formatFileSize = (size: number) => {
  if (size >= 1073741824) {
    return `${(size / 1073741824).toFixed(2)} GB`;
  }
  if (size >= 1048576) {
    return `${(size / 1048576).toFixed(2)} MB`;
  }
  return `${(size / 1024).toFixed(2)} KB`;
};

export const roundToTwoDecimals = (num: number): number => {
  return parseFloat(num.toFixed(2));
};

export const enumToObject = (
  enumObj: Record<string, string>,
): Record<string, string> => {
  return Object.keys(enumObj)
    .filter((key) => typeof enumObj[key] === 'string')
    .reduce(
      (acc, key) => {
        acc[key] = enumObj[key];
        return acc;
      },
      {} as Record<string, string>,
    );
};

export const menuOptions = (options: string[]) =>
  options.map((value) => ({
    value,
    label: value,
  }));
