import React, { useCallback, useMemo, useState } from 'react';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  IconButton,
  Menu,
  Typography,
  Box,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { DateTime } from 'luxon';
import { PromptFilters } from 'routes/prompt';

interface FilterPublishDateProps {
  onFilter: (filter: PromptFilters) => void;
  filters: PromptFilters;
  disabled: boolean;
}

export function FilterPublishDate({
  onFilter,
  filters,
  disabled,
}: FilterPublishDateProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!disabled) {
        setAnchorEl(event.currentTarget);
      }
    },
    [disabled],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const {
    isThisWeekSelected,
    isTwoWeeksSelected,
    isThisMonthSelected,
    isNoPublishDateSelected,
  } = useMemo(() => {
    const now = DateTime.now();
    return {
      isThisWeekSelected:
        filters.fromDate === now.startOf('day').toISODate() &&
        filters.toDate === now.endOf('week').toISODate(),
      isTwoWeeksSelected:
        filters.fromDate === now.toISODate() &&
        filters.toDate === now.plus({ weeks: 2 }).toISODate(),
      isThisMonthSelected:
        filters.fromDate === now.toISODate() &&
        filters.toDate === now.plus({ months: 1 }).toISODate(),
      isNoPublishDateSelected:
        filters.fromDate === undefined && filters.toDate === undefined,
    };
  }, [filters.fromDate, filters.toDate]);

  const handleMenuItemClick = useCallback(
    (fromDate?: string, toDate?: string) => {
      onFilter({ ...filters, fromDate, toDate });
      handleClose();
    },
    [filters, onFilter, handleClose],
  );

  const handleNoFilters = useCallback(() => {
    handleMenuItemClick(undefined, undefined);
  }, [handleMenuItemClick]);

  const handleThisWeek = useCallback(() => {
    const today = DateTime.now().startOf('day');
    const endOfWeek = today.endOf('week');
    handleMenuItemClick(today.toISODate(), endOfWeek.toISODate());
  }, [handleMenuItemClick]);

  const handleTwoWeeks = useCallback(() => {
    const today = DateTime.now();
    const twoWeeksLater = today.plus({ weeks: 2 });
    handleMenuItemClick(today.toISODate(), twoWeeksLater.toISODate());
  }, [handleMenuItemClick]);

  const handleThisMonth = useCallback(() => {
    const today = DateTime.now();
    const endOfMonth = today.plus({ months: 1 }).endOf('month');
    handleMenuItemClick(today.toISODate(), endOfMonth.toISODate());
  }, [handleMenuItemClick]);

  return (
    <Box
      display="flex"
      alignItems="center"
    >
      <Typography
        variant="subtitle2"
        mr={1}
      >
        Published
      </Typography>
      <IconButton
        size="small"
        onClick={handleClick}
        disabled={disabled}
      >
        <FilterListIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleNoFilters}>
          <Box
            display="flex"
            alignItems="center"
          >
            <Checkbox
              checked={isNoPublishDateSelected}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            />
            <ListItemText primary="Show all" />
          </Box>
        </MenuItem>
        <MenuItem onClick={handleThisWeek}>
          <Box
            display="flex"
            alignItems="center"
          >
            <Checkbox
              checked={isThisWeekSelected}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            />
            <ListItemText primary="This week" />
          </Box>
        </MenuItem>
        <MenuItem onClick={handleTwoWeeks}>
          <Box
            display="flex"
            alignItems="center"
          >
            <Checkbox
              checked={isTwoWeeksSelected}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            />
            <ListItemText primary="Next two weeks" />
          </Box>
        </MenuItem>
        <MenuItem onClick={handleThisMonth}>
          <Box
            display="flex"
            alignItems="center"
          >
            <Checkbox
              checked={isThisMonthSelected}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            />
            <ListItemText primary="This month" />
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
}
