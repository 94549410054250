import { createTheme } from '@mui/material';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#080808',
      paper: '#0d0d0d',
    },
    text: {
      primary: '#fff',
      secondary: '#B0B0B0',
    },
    primary: {
      main: '#fff',
      contrastText: '#000',
    },
    secondary: {
      main: '#f50057',
      contrastText: '#fff',
    },
    tertiary: {
      main: '#f50057',
      contrastText: '#fff',
    },
    error: {
      main: '#f44336',
    },
    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#29b6f6',
    },
    success: {
      main: '#66bb6a',
    },
    divider: '#ffffff22',
  },
  typography: {
    fontFamily:
      'Inter, "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI",' +
      'Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
    fontSize: 14,
    fontFeatureSettings: '"calt" 1, "zero" 1, "ss01" 1, "ss02" 1, "ss08" 1',
    h1: {
      fontSize: '2.125rem',
    },
    h2: {
      fontSize: '1.75rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.25rem',
    },
    h5: {
      fontSize: '1rem',
    },
    h6: {
      fontSize: '0.875rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#0d0d0d',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid',
          borderColor: '#2a2a2a',
          backgroundColor: '#0c0c0c',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff22',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #212121',
        },
      },
    },
  },
});
