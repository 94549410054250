import ModalBase from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useCallback } from 'react';

interface ModalProps {
  title?: string;
  isOpen: boolean;
  onClose?: (event: object, reason: 'backdropClick' | 'escapeKeyDown') => void;
  children: React.ReactNode;
}

export function Modal({ isOpen, onClose, children, title }: ModalProps) {
  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClose) {
        onClose(event, 'backdropClick');
      }
    },
    [onClose],
  );
  return (
    <ModalBase
      sx={{ bgcolor: 'rgb(0, 0, 0, 0.25)' }}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        borderRadius={1}
        position="absolute"
        top="50%"
        left="50%"
        width="min-content"
        minWidth={620}
        minHeight={320}
        maxHeight="80vh"
        overflow="auto"
        bgcolor="#1b1c1f"
        border="1px solid #272727"
        sx={{ transform: 'translate(-50%, -50%)' }}
      >
        <Box
          mb={3}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          borderBottom="1px solid #333333"
          py={1}
          px={2}
        >
          <Typography
            variant="h5"
            fontWeight={600}
          >
            {title}
          </Typography>

          <IconButton
            size="small"
            onClick={handleClose}
          >
            <Close fontSize="small" />
          </IconButton>
        </Box>
        {children}
      </Box>
    </ModalBase>
  );
}
