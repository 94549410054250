import { Box, CircularProgress } from '@mui/material';
import { useSuperfeelUserContext } from 'state/context/superfeelUser';
import Layout from 'layouts';
import { useEffect, useState } from 'react';
import { Outlet, Route, Routes, useParams } from 'react-router-dom';
import { GraphQLResult } from 'aws-amplify/api';
import { executeGraphqlOperation } from 'api';
import { getCurrentAuthCredentials } from 'api/auth/auth.api';
import { adminGetUserQuery } from 'graphql/queries';
import { SuperfeelUserResult } from 'types/api';
import UserHome from './home';
import UserData from './data';

interface AdminGetUser {
  user: SuperfeelUserResult;
}

export default function User() {
  const [loading, setLoading] = useState(true);
  const { setSuperfeelUser } = useSuperfeelUserContext();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchUser = async (): Promise<AdminGetUser> => {
      setLoading(true);
      const { jwt } = await getCurrentAuthCredentials();
      const input = {
        username: id,
      };
      const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
        adminGetUserQuery,
        { input },
        jwt,
      );

      setLoading(false);
      if (data) {
        const result = data as {
          adminGetUser: {
            user: SuperfeelUserResult;
          };
        };
        return {
          user: result.adminGetUser.user,
        };
      }
      throw new Error('Failed to fetch users');
    };
    const getData = async () => {
      const data = await fetchUser();
      setSuperfeelUser(data.user);
    };
    getData();
    return () => {
      setSuperfeelUser(null);
    };
  }, [id, setSuperfeelUser]);

  return (
    <Layout>
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="75vh"
          width="100%"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Outlet />
          <Routes>
            <Route
              index
              element={<UserHome />}
            />
            <Route
              path="data"
              element={<UserData />}
            />
          </Routes>
        </Box>
      )}
    </Layout>
  );
}
