import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { useUserContext } from 'state/context/user';
import MiniDrawer from '../components/organisms/drawer';

export default function Layout({ children }: { children: React.ReactNode }) {
  const { setUser } = useUserContext();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const userAttributes = await fetchUserAttributes();
        setUser({
          id: userAttributes.sub,
          email: userAttributes.email,
          name: userAttributes.name,
        });
      } catch (err) {
        console.log('error getting user...', err);
      }
    };
    getUserData();
  }, [setUser]);

  return (
    <Box>
      <MiniDrawer>
        <Container>{children}</Container>
      </MiniDrawer>
    </Box>
  );
}
