export const adminUpdatePromptMutation = `
  mutation adminUpdatePrompt($input: AdminUpdatePromptInput!) {
    adminUpdatePrompt(input: $input) {
      body
      category
      dateCreated
      type
    }
  }
`;
