/* eslint-disable react/jsx-props-no-spreading */
import { useMemo } from 'react';
import { Chip, ChipProps } from '@mui/material';
import { InviteStatus } from '@superfeel/types';

type StatusChipProps = {
  value?: InviteStatus;
};

export function StatusChip({ value }: StatusChipProps) {
  const chipProps: ChipProps = useMemo(() => {
    switch (value) {
      case 'PENDING':
        return { label: 'Pending', color: 'warning' };
      case 'ACCEPTED':
        return { label: 'Accepted', color: 'success' };
      case 'CANCELLED':
        return { label: 'Cancelled', color: 'error' };
      default:
        return { label: 'Unknown', color: 'default' };
    }
  }, [value]);

  return (
    <Chip
      {...chipProps}
      size="small"
    />
  );
}

export default StatusChip;
