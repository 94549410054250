import { useCallback, useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { DataGrid, GridPaginationModel } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { Loading, PostDetails } from 'components/atoms';
import { getCurrentAuthCredentials } from 'api/auth/auth.api';
import { adminSearchPostsQuery } from 'graphql/queries';
import { executeGraphqlOperation } from 'api';
import { GraphQLResult } from 'aws-amplify/api';
import { useSuperfeelUserContext } from 'state/context/superfeelUser';
import { SuperfeelPost } from '@superfeel/types';
import { columns } from './local';

interface AdminSearchPostsData {
  posts: SuperfeelPost[];
  totalCount: number;
}

const fetchPosts = async (
  page: number,
  pageSize: number,
  userId: string,
  jwt: string,
): Promise<AdminSearchPostsData> => {
  const postsInput = {
    limit: pageSize,
    startIndex: page * pageSize,
    authorUserIds: [userId],
  };
  const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
    adminSearchPostsQuery,
    { input: postsInput },
    jwt,
  );
  if (data) {
    const result = data as {
      adminSearchPosts: { posts: SuperfeelPost[]; totalCount: number };
    };
    return {
      posts: result.adminSearchPosts.posts,
      totalCount: result.adminSearchPosts.totalCount,
    };
  }
  throw new Error('Failed to fetch posts');
};

function DataFeels(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { superfeelUser } = useSuperfeelUserContext();
  const [state, setState] = useState({
    pageSize: 10,
    loading: false,
    page: 0,
    feels: [] as SuperfeelPost[],
    rowCount: 0,
    selectedRow: null as SuperfeelPost | null,
  });

  const { pageSize, loading, page, feels, rowCount, selectedRow } = state;

  const fetchAllFeels = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }));
    const { jwt } = await getCurrentAuthCredentials();
    const { posts, totalCount } = await fetchPosts(
      page,
      pageSize,
      superfeelUser!.userId,
      jwt,
    );
    setState((prev) => ({
      ...prev,
      feels: posts,
      rowCount: totalCount,
      loading: false,
    }));
  }, [page, pageSize, superfeelUser]);

  useEffect(() => {
    fetchAllFeels();
  }, [fetchAllFeels, id]);

  const handlePaginationModelChange = useCallback(
    (model: GridPaginationModel) => {
      setState((prev) => ({
        ...prev,
        page: model.page,
        pageSize: model.pageSize,
      }));
    },
    [],
  );

  const handleRowClick = useCallback((row: SuperfeelPost) => {
    if (row.audience === 'PUBLIC') {
      setState((prev) => ({ ...prev, selectedRow: row }));
    }
  }, []);

  return (
    <Box>
      {loading ? (
        <Loading />
      ) : (
        <Paper>
          <DataGrid
            rows={feels}
            columns={columns}
            paginationMode="server"
            rowCount={rowCount}
            pageSizeOptions={[5, 10, 20]}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={handlePaginationModelChange}
            autoHeight
            onRowClick={(params) => handleRowClick(params.row as SuperfeelPost)}
          />
        </Paper>
      )}
      <PostDetails
        selectedRow={selectedRow}
        onClose={() => setState((prev) => ({ ...prev, selectedRow: null }))}
      />
    </Box>
  );
}

export default DataFeels;
