import { useEffect, useState, ChangeEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
} from '@mui/material';
import { getCurrentAuthCredentials } from 'api/auth/auth.api';
import { executeGraphqlOperation } from 'api';
import { v4 as uuid } from 'uuid';
import { Loading, TableHeaderCell } from 'components/atoms';
import { useSnackbar } from 'state/context/snackBar';
import { SuperfeelUserResult } from 'types/api';
import { AdminSearchUsersInput } from '@superfeel/types';
import { GraphQLResult } from 'aws-amplify/api';
import { adminSearchUsersQuery } from 'graphql/queries';

interface AdminSearchUsersData {
  users: SuperfeelUserResult[];
  totalCount: number;
}

export default function Users() {
  const [users, setUsers] = useState<SuperfeelUserResult[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const fetchAllUsers = useCallback(
    async (
      newPage: number,
      newRowsPerPage: number,
      searchQuery?: string,
    ): Promise<AdminSearchUsersData | null> => {
      try {
        const { jwt } = await getCurrentAuthCredentials();
        const input: AdminSearchUsersInput = {
          includeHidden: true,
        };
        if (searchQuery) {
          input.search = {
            exactMatch: false,
            properties: ['username', 'first_name', 'last_name'],
            terms: [searchQuery],
          };
          input.limit = 5;
        } else {
          input.limit = newRowsPerPage;
          input.startIndex = newPage * newRowsPerPage;
        }
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          adminSearchUsersQuery,
          { input },
          jwt,
        );
        const result = data as {
          adminSearchUsers: {
            users: SuperfeelUserResult[];
            totalCount: number;
          };
        };
        return {
          users: result.adminSearchUsers.users,
          totalCount: result.adminSearchUsers?.totalCount,
        };
      } catch (e) {
        showSnackbar(`Failed to fetch users: ${e as string}`, 'error');
        return null;
      }
    },
    [showSnackbar],
  );

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        setLoading(true);
        const fetchedUsers = await fetchAllUsers(page, rowsPerPage, search);
        setUsers(fetchedUsers?.users || []);
        setTotalUsers(fetchedUsers?.totalCount || 0);
      } catch (error) {
        showSnackbar('Failed to fetch users', 'error');
      } finally {
        setLoading(false);
      }
    };

    getAllUsers();
  }, [fetchAllUsers, page, rowsPerPage, search, showSnackbar]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    if (newPage !== page && !loading) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleRowClick = (username: string) => {
    navigate(`/user/${username}`);
  };

  return (
    <Paper>
      <TextField
        variant="filled"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        fullWidth
        label="Search"
        size="small"
      />

      <TableContainer>
        {loading ? (
          <Loading minHeight="82vh" />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>Username</TableHeaderCell>
                <TableHeaderCell>First Name</TableHeaderCell>
                <TableHeaderCell>Last Name</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user) => (
                <TableRow
                  key={uuid()}
                  hover
                  onClick={() => handleRowClick(user.username)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.firstName}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalUsers}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
