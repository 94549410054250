import { PostType, PromptCategory } from '@superfeel/types';

export const CATEGORY: { value: PromptCategory; label: string }[] = [
  { value: 'EMOTION', label: 'Emotional Style' },
  { value: 'FAMILY', label: 'Family History' },
  { value: 'FUN', label: 'Just For Fun' },
  { value: 'GENDER', label: 'Gender Expression' },
  { value: 'INTERESTS', label: 'Unique Interests and Passions' },
  { value: 'NONE', label: 'None' },
  { value: 'PAST_RELATIONSHIPS', label: 'Prior Relationships' },
  { value: 'PERSONAL_GOALS', label: 'Personal Goals and Values' },
  { value: 'PHYSICAL_HEALTH', label: 'Physical Health' },
  { value: 'SELF_ESTEEM', label: 'Self Esteem' },
  { value: 'SEXUALITY', label: 'Sociosexuality' },
  { value: 'SOCIAL', label: 'Social Ties and Confidantes' },
  { value: 'STRESS', label: 'Stress and Stress Management' },
  { value: 'WORK', label: 'Work Identity' },
];

export const POST_TYPE: { value: PostType; label: string }[] = [
  { value: 'FOR_YOU', label: 'For you' },
  { value: 'FOTD', label: 'Feel of the day' },
  { value: 'REALS', label: 'Reals' },
];
