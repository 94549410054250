import { Edit } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { SuperFeelTableData } from 'routes/prompt';

interface PromptViewProps {
  prompt: SuperFeelTableData;
  onConfirm: () => void;
  onEdit: () => void;
}

export function PromptView({ prompt, onConfirm, onEdit }: PromptViewProps) {
  return (
    <Box
      flexDirection="column"
      display="flex"
    >
      <Box
        flexDirection="column"
        display="flex"
        px={2}
      >
        <Box
          flexDirection="column"
          display="flex"
          mb={4}
        >
          <Typography
            variant="h5"
            fontWeight={600}
            lineHeight={1.8}
          >
            Body
          </Typography>
          <Typography variant="body2">{prompt.body}</Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mb={4}
        >
          <Box
            flexDirection="row"
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="h5"
              fontWeight={600}
            >
              Category:
            </Typography>
            <Typography
              variant="body2"
              ml={2}
            >
              {prompt.category}
            </Typography>
          </Box>
          <Box
            flexDirection="row"
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="h5"
              fontWeight={600}
            >
              Type:
            </Typography>
            <Typography
              variant="body2"
              ml={2}
            >
              {prompt.type}
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box
            flexDirection="row"
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="h5"
              fontWeight={600}
            >
              Intensity:
            </Typography>
            <Typography
              variant="body2"
              ml={2}
            >
              {prompt.intensity}
            </Typography>
          </Box>
          {prompt.publishDate ? (
            <Box
              flexDirection="row"
              display="flex"
              alignItems="center"
            >
              <Typography
                variant="h5"
                fontWeight={600}
              >
                Publish date:
              </Typography>
              <Typography
                variant="body2"
                ml={2}
              >
                {prompt.publishDate}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        borderTop="1px solid #333333"
        py={1}
        mt={6}
        px={2}
      >
        <Button
          startIcon={<Edit fontSize="small" />}
          size="small"
          variant="outlined"
          onClick={onEdit}
        >
          Edit
        </Button>
        <Button
          size="small"
          variant="contained"
          sx={{ ml: 2 }}
          onClick={onConfirm}
        >
          Ok
        </Button>
      </Box>
    </Box>
  );
}
