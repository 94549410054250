export const adminGetUserInvitesQuery = `
  query AdminGetUserInvites($input: AdminGetUserInvitesInput!) {
    adminGetUserInvites(input: $input) {
      invitees {
        invitationId
        firstName
        lastName
        dateCreated
        phoneNumber
        status
      }
      totalCount
    }
  }
`;
