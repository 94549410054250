import React, {
  useMemo,
  useCallback,
  useState,
  ChangeEventHandler,
  ReactNode,
} from 'react';
import {
  Table as TableBase,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TablePagination,
  Typography,
  Box,
  TableSortLabel,
  styled,
  Paper,
  Chip,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Loading, TextField } from 'components/atoms';

type CellValue = string | number | boolean | null | undefined;

export type Column<T, K extends string | number | symbol> = {
  header: string | ReactNode;
  accessorKey: keyof T;
  cell?: (value: T[keyof T], row: T) => React.ReactNode;
  sortable?: boolean;
  sortField?: K;
};

interface TableCustomizedProps<T, K extends string | number | symbol> {
  isLoading?: boolean;
  columns: Column<T, K>[];
  data: T[];
  count: number;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  onPageChange?: (newPage: number) => void;
  onRowsPerPageChange?: (newRowsPerPage: number) => void;
  onSort?: (property: K, order: 'asc' | 'desc') => void;
  noDataMessage?: string;
  onSearchChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  searchValue?: string;
  filters?: { label: string; value?: string }[];
  onRemoveFilter?: (label: string) => void;
  sort?: {
    sortField: K;
    sortDirection: 'asc' | 'desc';
  };
  onRowClick?: (row: T) => void;
}

const ClickableTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export function TableCustomized<
  T extends Record<string, CellValue>,
  K extends string | number | symbol,
>({
  isLoading,
  columns,
  data,
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  onSort,
  noDataMessage = 'No data available',
  rowsPerPageOptions,
  sort,
  searchValue,
  onSearchChange,
  onRowClick,
  filters,
  onRemoveFilter,
}: TableCustomizedProps<T, K>): React.ReactElement {
  const [orderBy, setOrderBy] = useState<K | null>(sort?.sortField ?? null);
  const [order, setOrder] = useState<'asc' | 'desc'>(
    sort?.sortDirection ?? 'asc',
  );

  const TableHeadRoot = useMemo(
    () => (
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={String(column.accessorKey)}
              sortDirection={orderBy === column.sortField ? order : false}
            >
              {column.sortable ? (
                <TableSortLabel
                  active={orderBy === column.sortField}
                  direction={orderBy === column.sortField ? order : 'asc'}
                  onClick={() => {
                    const isAsc =
                      orderBy === column.sortField && order === 'asc';
                    const newOrder = isAsc ? 'desc' : 'asc';
                    setOrderBy(column.sortField as K);
                    setOrder(newOrder);
                    onSort?.(column.sortField as K, newOrder);
                  }}
                  hideSortIcon={orderBy !== column.sortField}
                >
                  {column.header}
                </TableSortLabel>
              ) : (
                column.header
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    ),
    [columns, orderBy, order, onSort],
  );

  const handleChangePage = useCallback(
    (_event: unknown, newPage: number) => {
      onPageChange?.(newPage);
    },
    [onPageChange],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onRowsPerPageChange?.(parseInt(event.target.value, 10));
      onPageChange?.(0);
    },
    [onRowsPerPageChange, onPageChange],
  );

  const NoDataMessage = useMemo(
    () => (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="body1">{noDataMessage}</Typography>
      </Box>
    ),
    [noDataMessage],
  );

  const renderFilters = useMemo(() => {
    if (!filters || filters.length === 0) return null;

    return (
      <Box
        display="flex"
        flexWrap="wrap"
        gap={1}
        p={1}
      >
        {filters.map((filter) => (
          <Chip
            key={filter.label}
            size="small"
            label={
              <Box
                display="flex"
                alignItems="center"
              >
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 'bold', mr: 0.5 }}
                >
                  {filter.label}:
                </Typography>
                <Typography variant="caption">{filter.value}</Typography>
              </Box>
            }
            onDelete={() => onRemoveFilter?.(filter.label)}
            deleteIcon={<Close fontSize="small" />}
          />
        ))}
      </Box>
    );
  }, [filters, onRemoveFilter]);

  if (isLoading && count === 0) return <Loading minHeight="60vh" />;

  return (
    <Paper>
      <TextField
        variant="filled"
        value={searchValue}
        onChange={onSearchChange}
        fullWidth
        label="Search"
        size="small"
      />
      {renderFilters}
      <TableContainer>
        {data.length === 0 ? (
          NoDataMessage
        ) : (
          <TableBase
            aria-label="superfeel admin table"
            sx={{ minWidth: '100%' }}
          >
            {TableHeadRoot}
            <TableBody>
              {data.map((row) => {
                const RowComponent = onRowClick ? ClickableTableRow : TableRow;

                return (
                  <RowComponent
                    key={row.accessorKey as string}
                    onClick={onRowClick ? () => onRowClick(row) : undefined}
                  >
                    {columns.map((column) => {
                      const cellContent = column.cell
                        ? column.cell(row[column.accessorKey], row)
                        : row[column.accessorKey];

                      return (
                        <TableCell key={String(column.accessorKey)}>
                          {cellContent}
                        </TableCell>
                      );
                    })}
                  </RowComponent>
                );
              })}
            </TableBody>
          </TableBase>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
