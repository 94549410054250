import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { SuperfeelPost } from '@superfeel/types';
import { formatDate } from 'common/utils';

interface PostDetailsProps {
  selectedRow: SuperfeelPost | null;
  onClose: () => void;
}

export function PostDetails({ selectedRow, onClose }: PostDetailsProps) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!!selectedRow}
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4">Post Details</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack
          direction="column"
          spacing={2}
        >
          <Stack direction="column">
            <FormLabel>PostID</FormLabel>
            <Typography>{selectedRow?.postId}</Typography>
          </Stack>
          <Stack direction="column">
            <FormLabel>Date</FormLabel>
            <Typography>{formatDate(selectedRow?.dateCreated)}</Typography>
          </Stack>
          <Stack direction="column">
            <FormLabel>Audience</FormLabel>
            <Typography>{selectedRow?.audience}</Typography>
          </Stack>
          <Stack direction="column">
            <FormLabel>Transcript</FormLabel>
            <Typography>{selectedRow?.transcript || ''}</Typography>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
