import React, { useCallback, useState } from 'react';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  IconButton,
  Menu,
  Typography,
  Box,
} from '@mui/material';
import { PostType } from '@superfeel/types';
import FilterListIcon from '@mui/icons-material/FilterList';
import { PromptFilters } from 'routes/prompt';
import { POST_TYPE } from '../../../../constants';

interface FilterTypeProps {
  filters: PromptFilters;
  onFilter: (values: PromptFilters) => void;
}

export function FilterType({ filters, onFilter }: FilterTypeProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleMenuItemClick = useCallback(
    (type?: PostType) => {
      onFilter({ ...filters, type });
      handleClose();
    },
    [filters, onFilter, handleClose],
  );

  return (
    <Box
      display="flex"
      alignItems="center"
    >
      <Typography
        variant="subtitle2"
        mr={1}
      >
        Type
      </Typography>
      <IconButton
        size="small"
        onClick={handleClick}
      >
        <FilterListIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuItemClick()}>
          <Box
            display="flex"
            alignItems="center"
          >
            <Checkbox
              checked={!filters.type}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            />
            <ListItemText primary="All types" />
          </Box>
        </MenuItem>
        {POST_TYPE.map((p) => (
          <MenuItem
            key={p.value}
            onClick={() => handleMenuItemClick(p.value)}
          >
            <Box
              display="flex"
              alignItems="center"
            >
              <Checkbox
                checked={p.value === filters.type}
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              />
              <ListItemText primary={p.label} />
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
