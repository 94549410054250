import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { TabPanel } from 'components/atoms';
import { v4 as uuid } from 'uuid';
import { DataFeels } from './components';

const tabLabels = ['Feels'];
const tabComponents = [<DataFeels />];

export default function UserData() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="user data tabs"
      >
        {tabLabels.map((label, index) => (
          <Tab
            key={uuid()}
            sx={{ fontWeight: 'bold' }}
            label={label}
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
          />
        ))}
      </Tabs>
      {tabComponents.map((component, index) => (
        <TabPanel
          key={uuid()}
          value={value}
          index={index}
        >
          {component}
        </TabPanel>
      ))}
    </Box>
  );
}
