/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';

type OptionType = {
  value: string;
  label: string;
};

type CustomSelectProps = Omit<SelectProps<string>, 'onChange'> & {
  options: OptionType[];
  label?: string;
  value?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  labelId?: string;
};

const CustomSelect = forwardRef<HTMLButtonElement, CustomSelectProps>(
  (props, ref) => {
    const {
      options,
      label,
      value,
      defaultValue,
      labelId = 'select-id',
      onChange,
      ...rest
    } = props;
    const [selectedValue, setSelectedValue] = useState(defaultValue || '');

    const handleChange = useCallback(
      (event: SelectChangeEvent<string>) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        onChange(newValue);
      },
      [onChange],
    );

    return (
      <Box sx={{ minWidth: 120, width: '100%' }}>
        <FormControl fullWidth>
          {label && <InputLabel id={labelId}>{label}</InputLabel>}
          <Select
            size="small"
            labelId={labelId}
            value={value || selectedValue}
            label={label}
            onChange={handleChange}
            IconComponent={UnfoldMoreRoundedIcon}
            ref={ref}
            {...rest}
          >
            {options.map((option: OptionType) => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  },
);

export default CustomSelect;
