import { createContext, useContext, useState, ReactNode, useMemo } from 'react';
import { User } from 'types/api';

interface UserContextState {
  user: User | null;
  setUser: (user: User | null) => void;
}

const UserContext = createContext<UserContextState | undefined>(undefined);

export const useUserContext = (): UserContextState => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
};

interface UserContextProviderProps {
  children: ReactNode;
}

export function UserContextProvider({
  children,
}: UserContextProviderProps): JSX.Element {
  const [user, setUser] = useState<User | null>(null);

  const contextValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}
