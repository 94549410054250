import Layout from 'layouts';
import { InviteUsers } from './components/invite-users';

export default function Invite() {
  return (
    <Layout>
      <InviteUsers />
    </Layout>
  );
}
