/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Box, Chip, Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { Add } from '@mui/icons-material';
import { getCurrentAuthCredentials } from 'api/auth/auth.api';
import { executeGraphqlOperation } from 'api';
import { useSnackbar } from 'state/context/snackBar';
import { TableCustomized } from 'components/molecules';
import { adminSearchPrompts } from 'graphql/queries/prompt';
import { Column } from 'components/molecules/table';
import Layout from 'layouts';
import { useDebounce } from 'hooks/use-debounce';
import { Calendar, Modal, Select, TextField } from 'components/atoms';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  AdminPromptInput,
  PostType,
  PromptCategory,
  SuperfeelPrompt,
} from '@superfeel/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { adminUpdatePromptMutation } from 'graphql/mutations/prompt';
import { PromptView } from './components';
import { CATEGORY, POST_TYPE } from '../../constants';
import { FilterType } from './components/filter-type';
import { FilterCategory } from './components/filter-category';
import { FilterPublishDate } from './components/filter-publish-date';
import formatDateFilter from './utils/formatDateFilter';

export type SuperFeelTableData = {
  body: string;
  category: PromptCategory;
  type: PostType;
  promptId: string;
  dateCreated: string;
  intensity: string;
  publishDate?: string;
};

export type PromptFilters = {
  type?: PostType;
  categories?: PromptCategory;
  fromDate?: string;
  toDate?: string;
};

type TableSort = {
  sort: 'dateCreated' | 'publishDate';
  sortDirection: 'asc' | 'desc';
};

const FormField = {
  body: 'body',
  category: 'category',
  type: 'type',
  dateCreated: 'dateCreated',
  intensity: 'intensity',
  publishDate: 'publishDate',
};

function Prompt() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [prompts, setPrompts] = useState<SuperFeelTableData[] | null>(null);
  const [mode, setMode] = useState<'VIEW' | 'UPDATE' | 'CREATE' | null>('VIEW');
  const [selectedPrompt, setSelectedPrompt] =
    useState<SuperFeelTableData | null>(null);
  const [paginationState, setPaginationState] = useState({
    page: 0,
    rowsPerPage: 10,
    totalCount: 0,
  });
  const [filters, setFilters] = useState<PromptFilters>({});
  const [tableSort, setTableSort] = useState<TableSort>({
    sort: 'publishDate', // NOTE: currently we do not support filtering by field.
    sortDirection: 'asc',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { isValid, errors, isSubmitting },
  } = useForm({
    defaultValues: {
      [FormField.body]: selectedPrompt?.body ?? '',
      [FormField.category]: selectedPrompt?.category ?? 'EMOTION',
      [FormField.type]: selectedPrompt?.type ?? 'FOTD',
      [FormField.intensity]: selectedPrompt?.intensity || '1',
      [FormField.publishDate]: selectedPrompt?.publishDate ?? '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        [FormField.body]: Yup.string()
          .required('Body is required')
          .min(2, 'Body must be at least 2 characters')
          .max(255, 'Body must not exceed 255 characters'),
        [FormField.category]: Yup.string()
          .required('Category is required')
          .oneOf(
            CATEGORY.map((option) => option.value),
            'Invalid category',
          ),
        [FormField.type]: Yup.string()
          .required('Type is required')
          .oneOf(
            POST_TYPE.map((option) => option.value),
            'Invalid type',
          ),
        [FormField.intensity]: Yup.number()
          .required('Intensity is required')
          .min(0, 'Intensity must be greater than or equal to 0')
          .max(3, 'Intensity can not be greater than 10'),
      }),
    ),
  });

  const categoryMapping = useMemo(
    () =>
      CATEGORY.reduce(
        (acc, { value, label }) => ({ ...acc, [value]: label }),
        {} as Record<PromptCategory, string>,
      ),
    [],
  );

  const postTypeMapping = useMemo(
    () =>
      POST_TYPE.reduce(
        (acc, { value, label }) => ({ ...acc, [value]: label }),
        {} as Record<PostType, string>,
      ),
    [],
  );

  const closeModal = useCallback(() => {
    setSelectedPrompt(null);
    setMode('VIEW');
    navigate('/prompt');
    reset();
  }, [navigate, reset]);

  const handleCreatePrompt = useCallback(() => {
    setSelectedPrompt(null);
    setMode('CREATE');
    reset({
      [FormField.body]: '',
      [FormField.category]: 'EMOTION',
      [FormField.type]: 'FOTD',
      [FormField.intensity]: '1',
      [FormField.publishDate]: undefined,
    });
    navigate('/prompt/new');
  }, [navigate, reset]);

  const fetchPrompts = useCallback(async () => {
    setIsLoading(true);
    try {
      const input = {
        limit: paginationState.rowsPerPage,
        startIndex: paginationState.page * paginationState.rowsPerPage,
        sortDirection: tableSort.sortDirection.toUpperCase(),
        search: debouncedSearchTerm || undefined,
        ...filters,
      };
      const { jwt } = await getCurrentAuthCredentials();
      const { data } = await executeGraphqlOperation<{
        adminSearchPrompts: {
          prompts: SuperFeelTableData[];
          totalCount: number;
        };
      }>(adminSearchPrompts, { input }, jwt);

      const result = data?.adminSearchPrompts ?? null;
      if (result) {
        setPaginationState((prev) => ({
          ...prev,
          totalCount: data?.adminSearchPrompts?.totalCount,
        }));
        setPrompts(result?.prompts);
      }
      return result;
    } catch (e) {
      showSnackbar(`Failed to fetch prompts: ${e as string}`);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [
    paginationState.rowsPerPage,
    paginationState.page,
    tableSort.sortDirection,
    debouncedSearchTerm,
    filters,
    showSnackbar,
  ]);

  const handleSort = useCallback(
    (_property: keyof SuperFeelTableData, order: 'asc' | 'desc') => {
      setTableSort({
        sort: 'dateCreated',
        sortDirection: order,
      });
    },
    [],
  );

  const handlePageChange = useCallback((newPage: number) => {
    setPaginationState((prev) => ({ ...prev, page: newPage }));
  }, []);

  const handleRowsPerPageChange = useCallback((newRowsPerPage: number) => {
    setPaginationState((prev) => ({
      ...prev,
      rowsPerPage: newRowsPerPage,
      page: 0,
    }));
  }, []);

  const handlePromptClick = useCallback(
    (prompt: SuperFeelTableData) => {
      setSelectedPrompt(prompt);
      setMode('UPDATE');
      navigate(`/prompt/${prompt.promptId}`);
    },
    [navigate],
  );

  const onFilter = useCallback((values: PromptFilters) => {
    setFilters((prev) => ({
      ...prev,
      ...values,
    }));
  }, []);

  const addFilterIfExists = useCallback(
    (filterMap: PromptFilters, key: keyof PromptFilters, label: string) => {
      if (filterMap[key]) {
        let value = filterMap[key];
        if (key === 'type') {
          value = POST_TYPE.find((pt) => pt.value === value)?.label || value;
        } else if (key === 'categories') {
          value = CATEGORY.find((cat) => cat.value === value)?.label || value;
        }
        return [{ label, value }];
      }
      return [];
    },
    [],
  );

  const handleRemoveFilter = useCallback((label: string) => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      // If we remove the type we should also clear dates.
      if (label === 'Type') {
        delete newFilters.type;
        delete newFilters.fromDate;
        delete newFilters.toDate;
      }
      if (label === 'Category') delete newFilters.categories;
      if (label === 'Published') {
        delete newFilters.fromDate;
        delete newFilters.toDate;
      }
      return newFilters;
    });
  }, []);

  const getTableFilters = useMemo(() => {
    const filterArray = [
      ...addFilterIfExists(filters, 'type', 'Type'),
      ...addFilterIfExists(filters, 'categories', 'Category'),
    ];

    if (filters.type === 'FOTD' && (filters.fromDate || filters.toDate)) {
      filterArray.push({
        label: 'Published',
        value: formatDateFilter(filters.fromDate, filters.toDate),
      });
    }

    return filterArray;
  }, [filters, addFilterIfExists]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPaginationState((prev) => ({ ...prev, page: 0 }));
  };

  const onSubmit: SubmitHandler<AdminPromptInput> = useCallback(
    async (formValues: AdminPromptInput) => {
      if (isLoading || isSubmitting || !isValid) return null;
      try {
        const { jwt } = await getCurrentAuthCredentials();
        const { data } = await executeGraphqlOperation<{
          adminUpdatePrompt: SuperfeelPrompt;
        }>(
          adminUpdatePromptMutation,
          {
            input: {
              action: mode === 'CREATE' ? 'CREATE' : 'UPDATE',
              prompt: {
                body: formValues.body,
                category: formValues.category,
                promptId: mode === 'CREATE' ? undefined : formValues.promptId,
                type: formValues.type,
                intensity: Number(formValues.intensity),
                publishDate: formValues.publishDate
                  ? DateTime.fromISO(formValues.publishDate).toISODate()
                  : null,
              },
            },
          },
          jwt,
        );

        if (data?.adminUpdatePrompt) {
          fetchPrompts();
          showSnackbar(
            `Prompt successfully ${mode === 'CREATE' ? 'created' : 'updated'}`,
            'success',
          );
          closeModal();
        } else {
          showSnackbar(
            `Could not ${mode === 'CREATE' ? 'create' : 'update'} prompt`,
            'error',
          );
        }
      } catch (error) {
        showSnackbar('Something went wrong!', 'error');
      }

      return null;
    },
    [
      closeModal,
      fetchPrompts,
      isLoading,
      isSubmitting,
      isValid,
      mode,
      showSnackbar,
    ],
  );

  useEffect(() => {
    fetchPrompts();
  }, [fetchPrompts]);

  useEffect(() => {
    if (id && id !== 'new' && prompts) {
      const prompt = prompts.find((p) => p.promptId === id);
      if (prompt) {
        setSelectedPrompt(prompt);
        setMode('VIEW');
        reset(prompt);
      }
    } else if (id === 'new') {
      setMode('CREATE');
      reset({
        [FormField.body]: '',
        [FormField.category]: 'EMOTION',
        [FormField.type]: 'FOTD',
        [FormField.intensity]: '1',
        [FormField.publishDate]: undefined,
      });
    } else {
      setSelectedPrompt(null);
      setMode('VIEW');
    }
  }, [id, prompts, reset]);

  const columns: Column<SuperFeelTableData, keyof SuperFeelTableData>[] =
    useMemo(
      () => [
        {
          header: 'Question',
          accessorKey: 'body',
          cell: (value) => <Typography variant="body2">{value}</Typography>,
        },
        {
          header: (
            <FilterCategory
              filters={filters}
              onFilter={onFilter}
            />
          ),
          accessorKey: 'category',
          cell: (value) => (
            <Chip
              label={categoryMapping[value as PromptCategory] || value}
              variant="outlined"
              size="small"
            />
          ),
        },
        {
          header: (
            <FilterType
              filters={filters}
              onFilter={onFilter}
            />
          ),
          accessorKey: 'type',
          cell: (value) => (
            <Typography variant="body2">
              {value === 'CUSTOM'
                ? 'Custom'
                : postTypeMapping[value as PostType] || value}
            </Typography>
          ),
        },
        {
          header: 'Created',
          accessorKey: 'dateCreated',
          cell: (value) => {
            if (!value) return '';
            return DateTime.fromISO(value).toFormat('M/d/yyyy');
          },
          sortable: true,
          sortField: 'dateCreated',
        },
        {
          header: (
            <FilterPublishDate
              onFilter={onFilter}
              filters={filters}
              disabled={filters.type !== 'FOTD'}
            />
          ),
          accessorKey: 'publishDate',
          cell: (value) => {
            if (!value) return '';
            return DateTime.fromISO(value).toFormat('M/d/yyyy');
          },
        },
      ],
      [categoryMapping, filters, onFilter, postTypeMapping],
    );

  return (
    <Layout>
      <Box
        marginBottom={2}
        component="header"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        width="100%"
      >
        <Button
          startIcon={<Add />}
          type="button"
          size="small"
          variant="contained"
          color="primary"
          onClick={handleCreatePrompt}
        >
          Create Prompt
        </Button>
      </Box>
      <TableCustomized<SuperFeelTableData, keyof SuperFeelTableData>
        isLoading={isLoading}
        columns={columns}
        data={prompts ?? []}
        count={paginationState.totalCount}
        page={paginationState.page}
        rowsPerPage={paginationState.rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        noDataMessage="Could not find prompts that match the specified criteria"
        onSort={handleSort}
        sort={{
          sortField: tableSort.sort,
          sortDirection: tableSort.sortDirection,
        }}
        onRowClick={handlePromptClick}
        searchValue={searchTerm}
        onSearchChange={handleSearchChange}
        filters={getTableFilters}
        onRemoveFilter={handleRemoveFilter}
      />
      <Modal
        isOpen={!!id || mode === 'CREATE'}
        onClose={closeModal}
        title={
          mode === 'VIEW'
            ? 'Prompt details'
            : mode === 'CREATE'
              ? 'Create Prompt'
              : 'Edit Prompt'
        }
      >
        <Box
          component="form"
          // @ts-expect-error no-misused-promises
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          {mode === 'VIEW' ? (
            selectedPrompt ? (
              <PromptView
                prompt={selectedPrompt}
                onConfirm={closeModal}
                onEdit={() => setMode('UPDATE')}
              />
            ) : null
          ) : (
            <Box
              display="flex"
              flexDirection="column"
            >
              <Box
                display="flex"
                flexDirection="column"
                px={2}
              >
                <TextField
                  {...register(FormField.body)}
                  label="Prompt text"
                  placeholder="i.e. What's been on your mind lately?"
                  error={!!errors[FormField.body]}
                  helperText={errors[FormField.body]?.message}
                  multiline
                  rows={2}
                />
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  my={3}
                >
                  <Box
                    mr={2}
                    width="100%"
                  >
                    <Controller
                      name={FormField.category}
                      control={control}
                      render={({ field }) => (
                        <Select
                          label="Category"
                          options={CATEGORY}
                          value={field.value as string}
                          onChange={(value) => field.onChange(value)}
                          error={!!errors[FormField.category]}
                        />
                      )}
                    />
                  </Box>

                  <Controller
                    name={FormField.type}
                    control={control}
                    render={({ field }) => (
                      <Select
                        label="Prompt type"
                        options={POST_TYPE}
                        value={field.value as string}
                        onChange={(value) => field.onChange(value)}
                        error={!!errors[FormField.type]}
                      />
                    )}
                  />
                </Box>

                <Box
                  mb={3}
                  display="flex"
                  flexDirection="row"
                >
                  <TextField
                    {...register(FormField.intensity)}
                    label="Intensity"
                    type="number"
                    inputProps={{ min: 0, max: 10 }}
                    error={!!errors[FormField.intensity]}
                    helperText={errors[FormField.intensity]?.message}
                    sx={{ mr: 2 }}
                  />
                  <Controller
                    name={FormField.publishDate}
                    control={control}
                    render={({ field }) => (
                      <Calendar
                        label="Prompt publish date"
                        value={field.value ? dayjs(field.value) : null}
                        disablePast
                        onChange={(date: dayjs.Dayjs | null) => {
                          field.onChange(
                            date && dayjs(date).isValid()
                              ? date.toISOString()
                              : null,
                          );
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                borderTop="1px solid #333333"
                px={2}
                py={1}
              >
                <Button
                  size="small"
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    if (mode === 'UPDATE') {
                      setMode('VIEW');
                    } else {
                      reset();
                      navigate('/prompt');
                    }
                  }}
                >
                  {mode === 'UPDATE' ? 'Cancel' : 'Close'}
                </Button>

                <Button
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  variant="contained"
                  size="small"
                  sx={{ ml: 2 }}
                >
                  {isSubmitting
                    ? 'Submitting...'
                    : mode === 'CREATE'
                      ? 'Create'
                      : 'Update'}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </Layout>
  );
}

export default memo(Prompt);
