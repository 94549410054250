import { ReactNode } from 'react';
import { UserContextProvider } from './user';
import { AppContextProvider } from './app';
import { SuperfeelUserContextProvider } from './superfeelUser';
import { SnackbarProvider } from './snackBar';

interface ContextProviderProps {
  children: ReactNode;
}

function ContextProvider({ children }: ContextProviderProps): JSX.Element {
  return (
    <AppContextProvider>
      <UserContextProvider>
        <SuperfeelUserContextProvider>
          <SnackbarProvider>{children}</SnackbarProvider>
        </SuperfeelUserContextProvider>
      </UserContextProvider>
    </AppContextProvider>
  );
}

export default ContextProvider;
