export const adminGetSignedUrlQuery = `
  query AdminGetSignedUrl ($input: AdminGetSignedUrlInput!) {
    adminGetSignedUrl(input: $input) {
      type
      url
      headers
    }
  }
`;

export const adminGetUserQuery = `query AdminGetUserHandler($input: AdminGetUserInput!) {
  adminGetUser(input: $input) {
    totalCount,
      user {
        id
        userId
        username
        email
        firstName
        lastName
        dateCreated
        dateDeleted
        isFlagged
        gender
        lastActivity
        stats { 
          follows
          hidden
          connected
          followedBy
        }
        profilePictureUri
        flags { 
          deactivatedAt
          deleteReason
          deletedAt
          lastDeactivatedAt
        }
        interactions { 
          sendMessage
          sendRequest
        }
      }
  }
}`;

export const adminSearchPostsQuery = `query AdminSearchPosts($input: AdminSearchPostsInput!) {
  adminSearchPosts(input: $input) {
      totalCount
      posts { 
        id
        transcript
        audience
        authorUserId
        authorId
        caption
        author { 
          id
          username
        }
        dateCreated
        metadata { 
          duration
          framesPerSecond
          language
        }
        transcript
        type
        videoStartTimestamp
        videoStopTimestamp
      }
  }
}`;

export const adminSearchUsersQuery = `query AdminSearchUsers($input: AdminSearchUsersInput!) {
  adminSearchUsers(input: $input) { 
    users {
      id
      userId
      username
      sub
      firstName
      lastName
      dateOfBirth
      email
      phone
      gender
      genderDesc
      dateCreated
      dateDeleted
      lastActivity
      profilePictureUri
      aboutMe
      isFlagged
      connectionStatus
    }
    totalCount
  }
}
`;
