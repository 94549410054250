import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { FC } from 'react';
import { amplifyConfig } from 'api/auth/amplify.config';
import { darkTheme } from './theme';
import { router } from './router';
import ContextProvider from './state/context';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(amplifyConfig);

const App: FC = function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <ContextProvider>
        <RouterProvider router={router} />
      </ContextProvider>
    </ThemeProvider>
  );
};

const WrappedApp = withAuthenticator(App, {
  hideSignUp: true,
  components: {
    Header() {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <img
            src="/img/superfeel-logo.jpg"
            alt="Logo"
            style={{ width: '100px' }}
          />
        </div>
      );
    },
  },
});

export default WrappedApp;
