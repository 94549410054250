import { createBrowserRouter } from 'react-router-dom';
import { Home, User, Settings, Invite, Prompt } from './routes';

export const router = createBrowserRouter([
  {
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/user/:id/*',
        element: <User />,
      },
      {
        path: '/settings/',
        element: <Settings />,
      },
      {
        path: '/invite/',
        element: <Invite />,
      },
      {
        path: '/prompt/',
        element: <Prompt />,
        children: [
          {
            path: ':id',
            element: null,
          },
        ],
      },
    ],
  },
]);
