import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  DateValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers/models';

interface CalendarProps {
  disablePast?: boolean;
  label?: string;
  value?: dayjs.Dayjs | null;
  onChange?: (
    value: dayjs.Dayjs | null,
    context: PickerChangeHandlerContext<DateValidationError>,
  ) => void;
}

export function Calendar({
  label,
  value,
  onChange,
  disablePast = false,
}: CalendarProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        sx={{ width: '100%' }}
        disablePast={disablePast}
        slotProps={{
          textField: {
            size: 'small',
          },
        }}
      />
    </LocalizationProvider>
  );
}
