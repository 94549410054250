import React, { useState } from 'react';
import BaseMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Typography } from '@mui/material';
import { MoreHorizRounded } from '@mui/icons-material';

type OptionType = {
  value: string;
  label: string;
};

type CustomMenuProps = {
  options: OptionType[];
  onChange: (value: string) => void;
  id?: string;
  defaultValue?: string;
};

export function Menu({
  options,
  onChange,
  id = 'menu-id',
  defaultValue,
}: CustomMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    defaultValue,
  );

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: string) => {
    setSelectedValue(value);
    onChange(value);
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
      >
        <MoreHorizRounded />
      </IconButton>
      <BaseMenu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': id,
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => handleMenuItemClick(option.value)}
            selected={option.value === selectedValue}
          >
            <Typography variant="body1">{option.label}</Typography>
          </MenuItem>
        ))}
      </BaseMenu>
    </>
  );
}
