import { Tab as BaseTab, TabProps, tabClasses } from '@mui/base/Tab';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import * as Icons from '@mui/icons-material';

type TabType = string | number | undefined;

interface ModelTabProps<T extends TabType> extends TabProps {
  value: T;
  children: ReactNode;
  iconName?: keyof typeof Icons;
}

const StyledBaseTab = styled(BaseTab)(
  ({ theme }) => `
  color: #9f9f9f;
  cursor: pointer;
  font-size: ${theme.typography.body2.fontSize};
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 6px 12px;
  margin: 8px;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #161616;
  }

  &:focus {
    color: ${theme.palette.text.primary};
    outline: none;
  }

  &.${tabClasses.selected} {
    background-color: rgba(45, 114, 217, 0.2);
    color: ${theme.palette.text.primary};
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: ${theme.palette.primary.main};
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
  }

  &.${tabClasses.selected}::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`,
);

export function Tab({ iconName, value, children }: ModelTabProps<TabType>) {
  const IconComponent = iconName ? Icons[iconName] : null;
  return (
    <StyledBaseTab value={value}>
      {IconComponent && (
        <IconComponent
          fontSize="small"
          style={{ marginRight: 8 }}
        />
      )}
      {children}
    </StyledBaseTab>
  );
}
