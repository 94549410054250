import { ReactNode } from 'react';
import {
  List,
  Box,
  CssBaseline,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Tooltip,
  Collapse,
  Skeleton,
  IconButton,
} from '@mui/material';
import {
  ExitToApp,
  Settings,
  ExpandMore,
  ExpandLess,
} from '@mui/icons-material';
import { useAppContext } from 'state/context/app';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import { useUserContext } from 'state/context/user';
import { v4 as uuid } from 'uuid';
import { SideBarItemProps } from 'types/app';
import { MainSideBarItems, UserSideBarItems } from 'common/nav';
import { useSnackbar } from 'state/context/snackBar';
import { Drawer, DrawerHeader } from './components/skeleton';
import SidebarItem from './components/sidebaritem';

interface AppDrawerProps {
  children: ReactNode;
}

export default function AppDrawer({ children }: AppDrawerProps) {
  const { drawerOpen, accountSettingsOpen, setAccountSettingsOpen } =
    useAppContext();
  const { user } = useUserContext();
  const { showSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const location = useLocation();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (err) {
      showSnackbar('Failed to sign out', 'error');
    }
  };

  const handleSignOutClick = () => {
    handleSignOut();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        elevation={0}
        variant="permanent"
        open={drawerOpen}
      >
        <DrawerHeader sx={{ justifyContent: 'space-between' }}>
          <Link to="/">
            <IconButton size="small">
              <Avatar src="/img/superfeel-logo.jpg" />
            </IconButton>
          </Link>
        </DrawerHeader>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          height="100%"
          pt={4}
        >
          <Box>
            <List>
              {MainSideBarItems.map((item: SideBarItemProps) => {
                return (
                  <SidebarItem
                    key={uuid()}
                    title={item.title}
                    icon={item.icon}
                    path={item.path}
                  />
                );
              })}
            </List>
            {location.pathname.includes('/user/') && (
              <Box>
                <Divider />
                <List>
                  {UserSideBarItems.map((item: SideBarItemProps) => {
                    return (
                      <SidebarItem
                        key={uuid()}
                        title={item.title}
                        icon={item.icon}
                        path={item.path}
                      />
                    );
                  })}
                </List>
              </Box>
            )}
          </Box>
          <Box>
            <Collapse in={accountSettingsOpen}>
              <List>
                <ListItem
                  disablePadding
                  sx={{ display: 'block' }}
                >
                  <Tooltip
                    title="Settings"
                    placement="right"
                  >
                    <ListItemButton
                      onClick={() => navigate('/settings')}
                      selected={location.pathname === '/settings'}
                    >
                      <ListItemIcon>
                        <Settings />
                      </ListItemIcon>
                      <ListItemText primary="Settings" />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
                <ListItem
                  disablePadding
                  sx={{ display: 'block' }}
                >
                  <Tooltip
                    title="Log Out"
                    placement="right"
                  >
                    <ListItemButton onClick={handleSignOutClick}>
                      <ListItemIcon>
                        <ExitToApp />
                      </ListItemIcon>
                      <ListItemText primary="Log Out" />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              </List>
            </Collapse>
            <List>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
              >
                <Tooltip
                  title="Account"
                  placement="right"
                >
                  <ListItemButton
                    onClick={() => setAccountSettingsOpen(!accountSettingsOpen)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: drawerOpen ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Avatar>
                        {user?.name ? (
                          user?.name.charAt(0)
                        ) : (
                          <Skeleton
                            height={20}
                            width={20}
                          />
                        )}
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        user ? `${user?.name}` : <Skeleton width={100} />
                      }
                      primaryTypographyProps={{
                        noWrap: true,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    />
                    {accountSettingsOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, py: 2, px: 1, mt: 3 }}
      >
        {children}
      </Box>
    </Box>
  );
}
