export const adminSearchPrompts = `
  query AdminSearchPrompts($input: AdminSearchPromptsInput!) {
    adminSearchPrompts(input: $input) {
      prompts {
        body
        category
        promptId
        dateCreated
        type
        intensity
        publishDate
      }
      totalCount
    }
  } 
`;
